/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const Page = ({ children, title = '', meta }) => (
    <>
        <Helmet>
            <title>{`Meerkats.AI | ${title} `}</title>
            {meta}
        </Helmet>

        <>{children}</>
    </>
)

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
}

export default Page
