import PropTypes from 'prop-types'
import axios from 'api/client'
import { createContext } from 'react'
import { isValidToken, setSession } from '../../utils/jwt'
import { useEffect, useReducer } from 'react'
import { usePostHog } from 'posthog-js/react'
import Spin from 'tailwindui/pages/components/Spin'
import { dispatch as d } from 'store'
// ----------------------------------------------------------------------

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
}

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        }
    },
    LOGIN: (state, action) => {
        const { user } = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user
        }
    },
    GUESTLOGIN: (state, action) => {
        const { user } = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user
        }
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user
        }
    },
    UPDATE: (state, action) => {
        const user = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user
        }
    }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state)

const AuthContext = createContext({
    ...initialState,
    method: 'jwt',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    updateUser: () => Promise.resolve(),
    guestLogin: () => Promise.resolve()
})

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node
}

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState)
    const posthog = usePostHog()
    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken')
                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)
                    const response = await axios.get('/auth/my-account', {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    })
                    const user = response.data
                    d({ type: 'SET_SESSION_USER', payload: user })
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user
                        }
                    })
                    posthog?.identify(user.id, {
                        email: user.email
                    })
                    posthog?.group('role', user.role)
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                })
            }
        }

        initialize()
    }, [])

    const login = async (payload, navigate) => {
        try {
            const response = await axios.post('auth/login', payload)
            if (response?.data?.status === false) {
                return response.data
            }
            const {
                tokens: {
                    access: { token }
                },
                user
            } = response.data
            setSession(token)

            dispatch({
                type: 'LOGIN',
                payload: {
                    user
                }
            })
            d({ type: 'SET_SESSION_USER', payload: user })
            const isAdmin = user?.role === 'admin'
            posthog?.identify(user.id, {
                email: user.email
            })
            posthog?.group('role', user.role)
            navigate(isAdmin)
        } catch (err) {
            const errorResponse = err?.response?.data
            return errorResponse ?? { status: false, error: 'Something went wrong' }
        }
    }
    const guestLogin = async (payload, navigate) => {
        try {
            const response = await axios.post('auth/guestlogin', payload)
            if (response?.data?.status === false) {
                return response.data
            }
            const {
                tokens: {
                    access: { token }
                },
                user
            } = response.data
            setSession(token)

            dispatch({
                type: 'GUESTLOGIN',
                payload: {
                    user
                }
            })
            d({ type: 'SET_SESSION_USER', payload: user })
            navigate(user?.role)
            return { status: true, user }
        } catch (err) {
            console.log(err)
            const errorResponse = err?.response?.data
            return errorResponse ?? { status: false, error: 'Something went wrong' }
        }
    }

    const register = async (data, cb) => {
        try {
            const response = await axios.post('/auth/register', data)
            const { status, otp, msg } = response.data

            cb(status, otp, msg)
            return
        } catch (err) {
            const errorResponse = err?.response?.data
            return errorResponse ?? { status: false, error: 'Something went wrong' }
        }
    }

    const logout = async () => {
        setSession(null)
        localStorage.clear()
        dispatch({ type: 'LOGOUT' })
        d({ type: 'SET_SESSION_USER', payload: null })
    }
    const updateUser = async (data) => {
        dispatch({ type: 'UPDATE', payload: data })
    }
    const verifyOtp = async (email, otp, cb, navigate) => {
        try {
            const response = await axios.post('/auth/verifyOtp', {
                email,
                otp
            })

            const { status, otpVerified, msg, apData } = response.data
            if (!status) {
                cb({ status, otpVerified, msg })
                return false
            }
            if (apData && apData.token) {
                localStorage.setItem('apToken', apData.token)
            }
            const {
                tokens: {
                    access: { token }
                },
                user
            } = response.data
            setSession(token)

            dispatch({
                type: 'REGISTER',
                payload: {
                    user
                }
            })
            d({ type: 'SET_SESSION_USER', payload: user })
            navigate()
        } catch (err) {
            const errorResponse = err?.response?.data
            return errorResponse ?? { status: false, error: 'Something went wrong' }
        }
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'jwt',
                login,
                logout,
                register,
                verifyOtp,
                updateUser,
                guestLogin
            }}
        >
            {state.isInitialized ? children : <Spin />}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }
