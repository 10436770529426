export const initialState = {
    include_industries: [],
    exclude_industries: [],
    company_size: [],
    company_type: [],
    include_description_keywords: '',
    exclude_description_keywords: '',
    countries: [],
    include_cities: [],
    exclude_cities: [],
    company: '',
    searchResults: {},
    seniority_tags: [],
    job_title_keywords: '',
    current_role_min_months_since_start_date: '',
    current_role_max_months_since_start_date: '',
    experience_count: '',
    job_description_keywords: '',
    headline_keywords: '',
    about_keywords: '',
    q_person_name: '',
    connection_count: '',
    follower_count: '',
    certification_keywords: '',
    supported_any_languages: [],
    school_names: [],
    organization_founded_year_range: [],
    person_days_in_current_title_range: [],
    person_total_yoe_range: [],
    revenue_range: [],
    person_time_zones: [],
    limit: 100
}

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_SEARCH':
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        case 'RESET_SEARCH':
            return initialState
        default:
            return state
    }
}

export default searchReducer
