/* eslint-disable no-useless-escape */
/* eslint-disable no-useless-backreference */
import { ArrowRightCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import TagIcon from '@mui/icons-material/TagOutlined'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonthOutlined'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmailOutlined'
import LinkIcon from '@mui/icons-material/LinkOutlined'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined'
import FunctionsIcon from '@mui/icons-material/Functions'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import ChecklistIcon from '@mui/icons-material/Checklist'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import moment from 'moment'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { DrawingPinIcon } from '@radix-ui/react-icons'
import _ from 'lodash'
import { state } from 'store'
import { DrawingPinFilledIcon } from '@radix-ui/react-icons'
export const getRunnableCells = (cells) => {
    return cells.filter((cell) => cell.isAi && cell.hasPrompt && cell.hasReference)
}

const headingActions = {
    action: [
        {
            title: 'Pin upto this column',
            icon: DrawingPinIcon,
            id: 'pin'
        },
        {
            title: 'Remove all Pinned Columns',
            icon: DrawingPinFilledIcon,
            id: 'remove-pin'
        },
        {
            title: 'Delete',
            icon: TrashIcon,
            id: 'delete'
        }
    ],

    sort: [
        // {
        //     title: 'Duplicate',
        //     icon: DocumentDuplicateIcon,
        //     id: 'duplicate'
        // },
        {
            title: 'Hide',
            icon: ArrowRightCircleIcon,
            id: 'hide'
        },
        {
            title: 'Sort A-Z',
            icon: ArrowRightCircleIcon,
            id: 'sort-az'
        },
        {
            title: 'Sort Z-A',
            icon: ArrowRightCircleIcon,
            id: 'sort-za'
        }
        // {
        //     title: 'Filter',
        //     icon: UserPlusIcon,
        //     id: 'filter'
        // }
    ],
    crud: [
        // {
        //     title: 'Rename Column',
        //     icon: PencilSquareIcon,
        //     id: 'rename'
        // },
        // {
        //     title: 'Edit Column',
        //     icon: PencilSquareIcon,
        //     id: 'edit'
        // },
        // {
        //     title: 'type',
        //     icon: DocumentDuplicateIcon,
        //     id: 'type',
        //     children: true,
        //     position: 'typechange'
        // },
        {
            title: 'Insert left',
            icon: SubdirectoryArrowLeftIcon,
            id: 'add-left',
            children: true,
            position: 'left'
        },
        {
            title: 'Insert right',
            icon: SubdirectoryArrowRightIcon,
            id: 'add-right',
            children: true,
            position: 'right'
        }
    ]
}
const icons = {
    text: TextFormatIcon,
    longText: TextFieldsIcon,
    string: TextFormatIcon,
    number: TagIcon,
    date: CalendarMonthIcon,
    email: AlternateEmailIcon,
    imageurl: InsertPhotoOutlinedIcon,
    url: LinkIcon,
    select: ExpandCircleDownOutlinedIcon,
    multiSelect: ChecklistIcon,
    checkbox: CheckBoxOutlinedIcon,
    boolean: CheckBoxOutlinedIcon,
    AI: PsychologyOutlinedIcon,
    Formula: FunctionsIcon,
    json: DataObjectOutlinedIcon,
    waterfall: WaterfallChartIcon,
    markdown: TextFieldsIcon,
    profile: AccountBoxIcon
}
const headingTypes = [
    // { title: 'AI', icon: icons.AI, id: 'Recipe' },
    { title: 'Text', icon: icons.text, id: 'text' },
    { title: 'Long Text', icon: icons.longText, id: 'longText' },
    { title: 'Number', icon: icons.number, id: 'number' },
    { title: 'Date', icon: icons.date, id: 'date' },
    { title: 'Time', icon: icons.date, id: 'time' },
    { title: 'DateTime', icon: icons.date, id: 'datetime-local' },
    { title: 'Image', icon: icons.imageurl, id: 'imageurl' },
    { title: 'Url', icon: icons.url, id: 'url' },
    { title: 'Single select', icon: icons.select, id: 'select' },
    { title: 'Multiple select', icon: icons.multiSelect, id: 'multiSelect' },
    { title: 'Boolean', icon: icons.checkbox, id: 'boolean' },
    { title: 'Formula', icon: icons.Formula, id: 'Formula' },
    { title: 'JSON', icon: icons.json, id: 'json' },
    { title: 'Markdown', icon: icons.waterfall, id: 'markdown' },
    { title: 'Profile', icon: icons.waterfall, id: 'profile' },
]
export const cellTypes = [
    { title: 'Input', icon: icons.text, id: 'Input' },
    { title: 'AI', icon: icons.AI, id: 'AI' },
    { title: 'Formula', icon: icons.Formula, id: 'Formula' },
    { title: 'Waterfall', icon: icons.waterfall, id: 'Waterfall' }
]
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
// const get
export const titleCase = (str) => {
    return str.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase()
    })
}
export const checkReference = (row, columns, inputString, returnRefObj = false) => {
    if (!inputString) return ''
    const labels = columns.map((cell) => cell.label.trim().toLowerCase()).sort((a, b) => b.length - a.length)
    const regex = generateLabelRegex(labels)
    let matches = [...inputString.toLowerCase().matchAll(regex)].map((match) => match[1])
    matches = [...new Set(matches)]
    if (!matches) {
        return ''
    }
    const referenceObject = matches.reduce((acc, label) => {
        const column = columns.find((col) => col.label.toString().toLowerCase() === label.toString().toLowerCase())
        if (column && row[column._id]) {
            acc[label] = row[column._id]
        }
        return acc
    }, {})
    if (returnRefObj) return referenceObject
    // check every key in label is in the referenceObject and has a value
    const allKeys = matches.filter((label) => !referenceObject[label.trim().toLowerCase()])
    return allKeys
}
// export function generateLabelRegex(labels) {
//     labels = labels.map((label) => label.trim().toLowerCase())
//     labels.sort((a, b) => b.length - a.length)
//     const pattern = new RegExp(`@(${labels.map((label) => label.replace(/\s+/g, '\\s+')).join('|')})\\b`, 'gi')
//     return pattern
// }
// Helper function to generate a regex pattern from label array
// Helper function to generate a regex pattern from label array
export const generateLabelRegex = (labels) => {
    // Escape special characters and ensure labels can handle spaces
    const escapedLabels = labels.map((label) => label.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/\s+/g, '\\s+'))
    return new RegExp(`@(${escapedLabels.join('|')})(?!\\w)`, 'gi') // Ensure exact match with non-word boundary or end
}

export const makeReferenceNew = (row, columns, inputString, currentLabel, datatype) => {
    currentLabel = currentLabel.toLowerCase().trim()
    // inputString = inputString.toLowerCase()

    let labels = columns
        .map((col) => col.label)
        .map((label) => label.toString().toLowerCase().trim())
        .filter((label) => label !== currentLabel)

    labels = labels.sort((a, b) => b.length - a.length) // Sort by length for greedy matching
    const regex = generateLabelRegex(labels)

    const matches = [...inputString.matchAll(regex)].map((match) => match[1])
    if (!matches.length) {
        return {}
    }

    // Create the referenceObject
    const referenceObject = matches.reduce((acc, label) => {
        const column = columns.find((col) => col.label.toString().toLowerCase() === label.toString().toLowerCase())
        if (column && row[column._id]) {
            let value = row[column._id] ? row[column._id].trim() : ''
            if (datatype === 'number' || !isNaN(value)) {
                value = parseFloat(value)
            }
            acc[label] = value
        }
        return acc
    }, {})

    // Replace each matched label in inputString with its value from referenceObject
    const msg = inputString
        .replace(regex, (_, key) => {
            if (datatype === 'number' || !isNaN(referenceObject[key])) {
                return ` ${referenceObject[key] || ''}`
            } else {
                return ` "${referenceObject[key] || ''}"`
            }
        })
        .replace(/@/g, '')
    return { msg, referenceObject }
}

export function executeFormula(cell, rowId, cells) {
    try {
        if (!cell.condition) return ''
        const row = state().datagrid.filteredRows.find((r) => r._id === rowId)
        if (cell.isConditional) {
            const conditions = cell.condition
            const columnMapping = checkReference(row, cells, conditions, true)
            const result = checkCondition(conditions, columnMapping)
            if (!result) return ''
        }
        const labels = cells.map((cell) => cell.label.trim().toLowerCase()).sort((a, b) => b.length - a.length)
        const regex = generateLabelRegex(labels)
        let matches = [...cell.prompt.toLowerCase().matchAll(regex)].map((match) => match[1])
        matches = [...new Set(matches)]
        if (!matches) {
            return ''
        }
        const referenceObject = matches.reduce((acc, label) => {
            const column = cells.find((col) => col.label.toString().toLowerCase() === label.toString().toLowerCase())
            if (column && row[column._id]) {
                acc[label] = row[column._id]
            }
            return acc
        }, {})
        const formulaString = cell.prompt
            .replace(regex, (_, key) => {
                key = key.trim().toLowerCase()
                return referenceObject[key] ?? 0
            })
            .replace(/@/g, '')
        const a = eval(formulaString)
        return a
    } catch (e) {
        console.error(e)
        return ''
    }
}
export function executeFormulaSingleRowId(rowId, formula, cells, datatype) {
    const row = state().datagrid.filteredRows.find((r) => r._id === rowId)
    return executeFormulaSingleRow(row, formula, cells, datatype)
}

export function executeFormulaSingleRow(row, formula, cells, datatype) {
    const toReturn = {
        input: '',
        output: '',
        labels: []
    }
    try {
        if (!formula || !row || !cells) return toReturn
        const labels = cells.map((cell) => cell.label.trim().toLowerCase()).sort((a, b) => b.length - a.length)
        toReturn.labels = labels
        const regex = generateLabelRegex(labels)
        let matches = [...formula.toLowerCase().matchAll(regex)].map((match) => match[1])
        matches = [...new Set(matches)]
        const { msg, referenceObject } = makeReferenceNew(row, cells, formula, '', datatype)
        const output = eval(msg)
        matches = [...new Set(matches)]
        if (!matches) {
            return toReturn
        }
        // return { input: columnMapping, output, labels: matches }
        Object.keys(referenceObject).map((key) => {
            const lowerKey = key.toLowerCase()
            referenceObject[lowerKey] = referenceObject[key]
        })
        toReturn.input = referenceObject
        toReturn.output = output
        toReturn.labels = matches
        return toReturn
    } catch (e) {
        console.error(e)
        return toReturn
    }
}
export function checkCondition(condition, columnMapping) {
    if (!condition) return true
    let str = condition ? condition.toLowerCase() : ''
    Object.keys(columnMapping).forEach((key) => {
        // Escape spaces in the key for regex
        const escapedKey = key.replace(/\s+/g, '\\s+')
        const regex = new RegExp(`@${escapedKey}`, 'gi')
        str = str.replace(regex, columnMapping[key])
    })

    try {
        // Evaluate the condition safely
        return eval(str)
    } catch (e) {
        console.error('Error evaluating condition:', e)
        return false
    }
}

export function isMarkdown(input) {
    const markdownPattern = /(\n|^)#{1,6}\s|(\*\*|__)(.*?)\1|[*_-]{3,}|!\[.*?\]\(.*?\)|\[(.*?)\]\(.*?\)|^\d+\.\s|\n\s*[*-]\s/
    return markdownPattern.test(input)
}
export { headingActions, headingTypes, classNames, icons }

export const checkForRunAiCellOnRowUpdate = (row, columns) => {
    const aiCells = columns.filter((col) => col.type === 'AI' || col.type === 'Waterfall')
    if (aiCells.length === 0) return false
    const nonAiCells = columns.filter((col) => !(col.type === 'AI' || col.type === 'Waterfall'))
    if (nonAiCells.length === 0) return false
    const nonAiCellIdMap = {}
    nonAiCells.forEach((col) => {
        nonAiCellIdMap[col.label.trim().toLowerCase()] = col._id
    })
    let toRunCells = []
    aiCells.forEach((cell) => {
        const value = row[cell._id]
        if (!value) {
            const hasReferenceValue = checkReference(row, columns, cell.prompt)
            if (hasReferenceValue.length === 0) {
                toRunCells.push(cell._id)
            }
        }
    })
    return toRunCells
}
export const getInitalAndColor = (item, index) => {
    const initial = item.name.charAt(0).toUpperCase() + item.name.charAt(1)
    const colors = ['bg-red-200', 'bg-blue-200', 'bg-green-200', 'bg-yellow-200', 'bg-indigo-200', 'bg-pink-200', 'bg-purple-200']
    const color = colors[index % colors.length]
    return { initial, color }
}
export const DateFormats = [
    { title: moment().format('DD/MM/YYYY').toString(), id: 'DD/MM/YYYY' },
    { title: moment().format('DD-MM-YYYY').toString(), id: 'DD-MM-YYYY' },
    { title: moment().format('MM/DD/YYYY').toString(), id: 'MM/DD YYYY' },
    { title: moment().format('MM-DD-YYYY').toString(), id: 'MM-DD-YYYY' },
    { title: moment().format('DD MMM, YYYY').toString(), id: 'DD MMM, YYYY' }
]
export const abbreviate = (number) => {
    // like 10, 100, 1K, 10K, 100K, 1M, 10M, 100M, 1B, 10B, 100B, 1T
    if (number < 1000) return number
    const tier = (Math.log10(number) / 3) | 0
    if (tier === 0) return number
    const suffixes = ['K', 'M', 'B', 'T']
    const suffix = suffixes[tier - 1]
    const scale = Math.pow(10, tier * 3)
    const scaled = number / scale
    return scaled.toFixed(1) + suffix
}
export const abbreviateNumber = (number, suffix, prefix) => {
    const a = abbreviate(number)
    return `${prefix ?? ''} ${a} ${suffix ?? ''}`
}
export const getFormattedDate = (date, format) => {
    format = format && format.id ? format.id : format ? format : 'DD/MM/YYYY'
    if (!date) return ''
    if (!moment(date).isValid()) return ''
    if (!format) return moment(date).format('DD/MM/YYYY')
    if (moment(date).format(format) === 'Invalid date') return moment(date).format('DD/MM/YYYY')
    return moment(date).format(format)

    // return moment(date).format(format)
}

// check for tool data errors
export const checkForErrors = (data) => {
    let error = null
    data.forEach((run) => {
        const values = Object.values(run)
        if (values && _.isArray(values)) {
            values.forEach((value) => {
                if (value && typeof value === 'object' && value.error) {
                    error = value.error
                }
            })
        }
    })
    return error
}
export const getOperator = (datatype) => {
    switch (datatype) {
        case 'number':
            return [
                { title: 'Greater than', id: '>' },
                { title: 'Less than', id: '<' },
                { title: 'Equal to', id: '==' },
                { title: 'Not equal to', id: '!=' },
                { title: 'Greater than or equal to', id: '>=' },
                { title: 'Less than or equal to', id: '<=' }
            ]
        case 'date':
            return [
                { title: 'After', id: '>' },
                { title: 'Before', id: '<' },
                { title: 'On', id: '==' },
                { title: 'Not on', id: '!=' },
                { title: 'After or on', id: '>=' },
                { title: 'Before or on', id: '<=' },
                { title: 'Is Empty', id: 'empty' },
                { title: 'Is Not Empty', id: 'notempty' }
            ]
        case 'checkbox':
            return [
                { title: 'Checked', id: 'trye' },
                { title: 'Unchecked', id: 'false' }
            ]
        case 'select':
            return [
                { title: 'Equal to', id: '==' },
                { title: 'Not equal to', id: '!=' },
                { title: 'Is Empty', id: 'empty' },
                { title: 'Is Not Empty', id: 'notempty' }
            ]
        case 'multiSelect':
            return [
                { title: 'Contains', id: 'contains' },
                { title: 'Does not contain', id: 'does not contain' },
                { title: 'Is Empty', id: 'empty' },
                { title: 'Is Not Empty', id: 'notempty' }
            ]
        default:
            return [
                { title: 'Contains', id: 'contains' },
                { title: 'Does not contain', id: 'does not contain' },
                { title: 'Equal to', id: '==' },
                { title: 'Not equal to', id: '!=' },
                { title: 'Is Empty', id: 'empty' },
                { title: 'Is Not Empty', id: 'notempty' },
                { title: 'Errored', id: 'error' },
                { title: 'Not found', id: 'notfound' },
                { title: 'In Queue', id: 'inqueue' },
                { title: 'Processing', id: 'processing' }
            ]
    }
}
// one filter is an object with column, operator and value
// { column: { _id: '123', label: 'Name', datatype: 'text }, operator: {title: 'Is Empty', id : ''}, value: 'John' }
// { column: { _id: '123', label: 'Name' , datatype: 'text}, operator: {title: 'Contains', id : 'contains'}, value: 'John' }
// { column: { _id: '123', label: 'Name', datatype: 'text }, operator: {title: 'Contains', id : 'contains'}, value: 'John' }
// { column: { _id: '123', label: 'Created At', datatype: 'date }, operator: {title: 'After or on', id : '>='}, value: '13-12-2003' }
// { column: { _id: '123', label: 'Empyoees count', datatype: 'number }, operator: {title: 'Greater than', id : '>'}, value: 40 }
export const filterRows = (rows, filters, rowFilter) => {
    try {
        const validFilters = filters.filter((filter) => {
            if (filter.column && filter.operator) {
                if (filter.operator.id === 'empty' || filter.operator.id === 'notempty' || filter.operator.id === 'error' || filter.operator.id === 'notfound' || filter.operator.id === 'inqueue' || filter.operator.id === 'processing') {
                    return true
                } else {
                    return filter.value !== undefined && filter.value !== null && filter.value !== ''
                }
            } else {
                return false
            }
        })
        const filteredRows = rows.filter((row) => {
            let mainOperator = 'And'
            const anyFilter = validFilters.length > 0
            if (!anyFilter) return true
            const values = validFilters.map((filter) => {
                let include = true
                // check column datatype then conver string value to date object if datatype is date or number if datatype is number or boolean if datatype is checkbox
                const column = filter.column
                const operator = filter.operator
                if (operator && operator.id) {
                    operator.id = operator.id.toString().toLowerCase().trim()
                }

                let value = filter.value
                if (value && typeof value === 'string') {
                    value = value.trim().toLowerCase()
                }

                const andOr = filter.condition
                if (andOr) {
                    mainOperator = andOr.toString().toLowerCase().trim()
                }
                let rowValue = row[column._id]
                if (rowValue && typeof rowValue === 'string') {
                    rowValue = rowValue.trim().toLowerCase()
                }
                if (operator.id === 'empty') {
                    include = include && !rowValue
                } else if (rowValue === undefined || rowValue === null) {
                    include = false
                }

                if (column.datatype === 'date') {
                    rowValue = new Date(rowValue)
                    value = new Date(value)
                }
                if (column.datatype === 'number') {
                    rowValue = parseFloat(rowValue)
                    value = parseFloat(value)
                }
                if (column.datatype === 'checkbox') {
                    rowValue = rowValue === 'true'
                    value = value === 'true'
                }
                if (column.datatype === 'select' || column.datatype === 'multiSelect') {
                    rowValue = rowValue.toString()
                    value = value.toString().toLowerCase()
                }
                if (operator.id === '==') {
                    include = include && rowValue === value
                }
                if (operator.id === '!=') {
                    include = include && rowValue !== value
                }
                if (operator.id === '>') {
                    include = include && rowValue > value
                }
                if (operator.id === '<') {
                    include = include && rowValue < value
                }
                if (operator.id === '>=') {
                    include = include && rowValue >= value
                }
                if (operator.id === '<=') {
                    include = include && rowValue <= value
                }
                if (operator.id === 'contains') {
                    include = include && rowValue.toString().toLowerCase().includes(value.toString().toLowerCase())
                }
                if (operator.id === 'does not contain') {
                    include = include && !rowValue.toString().toLowerCase().includes(value.toString().toLowerCase())
                }
                if (operator.id === 'checked') {
                    include = include && rowValue
                }
                if (operator.id === 'unchecked') {
                    include = include && !rowValue
                }
                if (operator.id === '') {
                    include = include && !rowValue
                }
                if (operator.id === 'empty') {
                    include = include && !rowValue
                }
                if (operator.id === 'notempty') {
                    include = include && rowValue
                }
                if (operator.id === 'error') {
                    include = include && typeof rowValue === 'string' && rowValue.toLowerCase().startsWith('error')
                }
                if (operator.id === 'notfound') {
                    include = include && typeof rowValue === 'string' && rowValue.toLowerCase().startsWith('not found')
                }
                if (operator.id === 'inqueue') {
                    include = include && ((typeof rowValue === 'string' && rowValue.toLowerCase().startsWith('inqueue')) || (typeof rowValue === 'string' && rowValue.toLowerCase().startsWith('queued...')))
                }
                if (operator.id === 'processing') {
                    include = include && typeof rowValue === 'string' && rowValue.toLowerCase().startsWith('processing...')
                }
                return include
            })
            if (mainOperator === 'and') {
                return values.every((v) => v)
            } else {
                return values.some((v) => v)
            }
        })
        if (rowFilter.startingRow && !isNaN(rowFilter.startingRow) && rowFilter.execute) {
            const start = parseInt(rowFilter.startingRow)
            const end = parseInt(rowFilter.endingRow)
            const finalEnd = end === 0 ? filteredRows.length : start + end
            console.log('start', start, 'end', end, 'finalEnd', finalEnd)
            return filteredRows.slice(start, finalEnd)
        }
        return filteredRows
    } catch (e) {
        console.error(e)
        return rows
    }
}
export const checkRefAndPrompt = (rows, columns, column, rowIndex) => {
    const hasPrompt = !!(column.prompt && column.prompt.length > 0)
    const hasReference = checkReference(rows[rowIndex], columns, column.prompt)
    return { hasPrompt, hasReference : !hasReference || (hasReference && _.isArray(hasReference) && hasReference.length === 0) }
}
export const getHighlightedCells = (selection, filteredRows, headings) => {
    const cols = headings.filter(a => !a.hidden)
    const colIds = headings.filter(a => !a.hidden).map((col) => col._id)
    const aiCellsIds = headings.filter(a => a.type === 'AI' || a.type === 'Waterfall').map((col) => col._id)

    const items = []
    if (selection && selection.current && selection.current.range && selection.current.cell) {
        const range = selection.current.range
        const cell = selection.current.cell
        if (range && range.height === 1 && range.width === 1 && cell && cell.length === 2) {
            const columnIndex = cell[0]
            const rowIndex = cell[1]
            const rowId = filteredRows[rowIndex]?._id
            const cellId = colIds[columnIndex]
            const isAi = aiCellsIds.includes(cellId)
            if (isAi) {
                const { hasPrompt, hasReference } = checkRefAndPrompt(filteredRows, headings, cols[columnIndex], rowIndex)
                items.push({ rowId, cellId, isAi, rowIndex, columnIndex, hasPrompt, hasReference })
            } else {
                items.push({ rowId, cellId, isAi, rowIndex, columnIndex })
            }
        } else {
            const { x, y, height, width } = range
            for (let i = x; i < x + width; i++) {
                for (let j = y; j < y + height; j++) {
                    const rowId = filteredRows[j]?._id
                    const cellId = colIds[i]
                    const isAi = aiCellsIds.includes(cellId)
                    if (isAi) {
                        const { hasPrompt, hasReference } = checkRefAndPrompt(filteredRows, headings, cols[i], j)
                        items.push({ rowId, cellId, isAi, rowIndex: j, columnIndex: i, hasPrompt, hasReference })
                    } else {
                        items.push({ rowId, cellId, isAi: aiCellsIds.includes(cellId), rowIndex: j, columnIndex: i })
                    }
                }
            }
        }
    }
    return items
}
export const getSelectedRows = (selection, filteredRows) => {
    if (selection && selection.rows && selection.rows.items && selection.rows.items.length > 0) {
        let rows = []
        for (const item of selection.rows.items) {
            if (item && item.length === 2) {
                for (let i = item[0]; i < item[1]; i++) {
                    rows.push(filteredRows[i]?._id)
                }
            }
        }
        return rows
    }
    return []
}
export const checkDuplicateRows = (rows, columns, labels) => {
    const uniqueRows = []
    const uniqueRowIds = []
    const duplicateRowIds = []
    const gridIds = []
    const finalColumns = columns.filter((col) => labels.includes(col.label))
    if (labels && labels.length > 0 && finalColumns.length > 0) {
        finalColumns.map((col) => {
            const values = []
            rows.forEach((row) => {
                let value = row[col._id]
                if (value === undefined || value === null) {
                    value = ''
                }
                const str = value.toString().trim().toLowerCase()
                if (!values.includes(str)) {
                    values.push(str)
                } else {
                    gridIds.push({ rowId: row._id, columnId: col._id })
                }
            })
        })
        return gridIds
    }

    rows.forEach((row) => {
        const rowValues = (labels && labels.length > 0 ? finalColumns : columns)
            .map((col) => row[col._id])
            .filter((val) => val !== undefined && val !== null && val !== '')
            .map((val) => val.toString().trim().toLowerCase())
        const rowString = rowValues.join('')
        if (!uniqueRowIds.includes(rowString)) {
            uniqueRows.push(row)
            uniqueRowIds.push(rowString)
        } else {
            duplicateRowIds.push(row._id)
        }
    })
    return duplicateRowIds
}
export function humanReadableToFloat(value) {
    // Remove any non-numeric characters except decimal points, commas, or suffixes like 'k', 'M', 'B'
    value = value.replace(/[^0-9.,kmbBKM]/g, '').toLowerCase()

    // Remove commas from numbers
    value = value.replace(/,/g, '')

    let multiplier = 1

    // Check if the string ends with 'k', 'm', or 'b' and adjust the multiplier accordingly
    if (value.endsWith('k')) {
        multiplier = 1e3 // Thousand
        value = value.replace('k', '')
    } else if (value.endsWith('m')) {
        multiplier = 1e6 // Million
        value = value.replace('m', '')
    } else if (value.endsWith('b')) {
        multiplier = 1e9 // Billion
        value = value.replace('b', '')
    }

    // Convert the cleaned-up value to a float and apply the multiplier
    return parseFloat(value) * multiplier
}
export const inputArgs = ['url', 'query']
export const getSlugFromName = (name) => {
    // return pascal case
    return name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}
export const findNextCellToFocus = (cell, rowId, direction) => {
    const rows = state().datagrid.filteredRows
    const columns = state().datagrid.headings
    const rowIds = state().datagrid.filteredRows.map((r) => r._id)
    const row = rows.find((r) => r._id === rowId)
    let nextCell = null
    let rowIndex = rowIds.findIndex((r) => r === row._id)
    if (direction === 'next') {
        const currentColumnIndex = columns.findIndex((c) => c._id === cell._id)
        if (currentColumnIndex + 1 < columns.length) {
            nextCell = columns[currentColumnIndex + 1]._id
        } else {
            nextCell = columns[0]._id
            if (rowIndex + 1 < rowIds.length) {
                rowIndex = rowIndex + 1
            } else {
                nextCell = null
            }
        }
    } else if (direction === 'prev') {
        const currentColumnIndex = columns.findIndex((c) => c._id === cell)
        if (currentColumnIndex - 1 >= 0) {
            nextCell = columns[currentColumnIndex - 1]._id
        } else {
            nextCell = columns[columns.length - 1]._id
            if (rowIndex - 1 >= 0) {
                rowIndex = rowIndex - 1
            } else {
                nextCell = null
            }
        }
    }
    if (!nextCell) return null
    const rowIdNew = rowIds[rowIndex]
    return { rowId: rowIdNew, cellId: nextCell, rowIndex, rows, headings: columns }
}
