import * as ActionTypes from './Types'

const initialState = {
    authenticated: false,
    backdrop: false,
    user: null,
    emailSent: false,
    passwordRecovred: false,
    loading: false,
    assessment: null,

    toast: {
        severity: null,
        open: false,
        message: ''
    },
    tokenVerification: null
}

export default (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case 'SET_SESSION_USER':
            return { ...state, user: payload }
        case ActionTypes.SET_TOKEN_VERIFICATION_STATUS:
            return { ...state, tokenVerification: payload }
        case ActionTypes.SET_ASSESSMENT:
            return { ...state, assessment: payload }
        case ActionTypes.LOGOUT:
            return initialState
        case ActionTypes.BACKDROP:
            return { ...state, backdrop: payload }
        case ActionTypes.SET_TOAST:
            return { ...state, toast: payload }
        case ActionTypes.FORGET_EMAIL_SENT:
            return { ...state, emailSent: payload }
        case ActionTypes.RECOVER_PASSWORD:
            return { ...state, passwordRecovred: payload }
        case ActionTypes.LOADING:
            return { ...state, loading: payload, error: null }

        case ActionTypes.LOGIN_FAILED:
            return { ...state, loading: false, error: payload }

        case ActionTypes.LOGIN_SUCCESS:
            localStorage.setItem('tapUser', JSON.stringify(payload))
            return { ...state, loading: false, authenticated: true, user: payload }

        case ActionTypes.SIGN_UP_FAILED:
            return { ...state, loading: false, error: payload }
        case ActionTypes.SIGN_UP_SUCCESS:
            localStorage.setItem('tapUser', JSON.stringify(payload))
            return { ...state, loading: false, authenticated: true, user: payload }
        default:
            return state
    }
}
