import { m } from 'framer-motion'
import { styled } from '@mui/material/styles'
// @mui
import { Button, Typography, Container, Stack } from '@mui/material'
// components
import Page from '../components/Page'
// assets
import SeverErrorIllustration from '../assets/illustration_500'

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function Page500() {
    const goBack = () => {
        window.history.back()
        window.location.reload()
    }

    const goHome = () => {
        window.location = '/'
    }
    return (
        <Page title='500 Internal Server Error'>
            <Container>
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <m.div>
                        <Typography variant='h3' paragraph>
                            500 Internal Server Error
                        </Typography>
                    </m.div>

                    <m.div>
                        <Typography sx={{ color: 'text.secondary' }}>There was an error, please try again later.</Typography>
                    </m.div>

                    <m.div>
                        <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
                    </m.div>
                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                        <Button
                            variant='outlined'
                            onClick={goBack}
                            // startIcon={<Iconify icon='eva:arrow-circle-left-outline' sx={{ color: 'info' }} />}
                        >
                            Go Back
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={goHome}
                            // endIcon={<Iconify icon='eva:home-outline' sx={{ color: 'info' }} />}
                        >
                            Go to Home
                        </Button>
                    </Stack>
                </ContentStyle>
            </Container>
        </Page>
    )
}
