import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
// hooks
import useAuth from '../hooks/useAuth'
// pages
import Login from '../views/auth/Login'
import Spin from 'tailwindui/pages/components/Spin'
import Forbidden from '../views/Page403'
import { usePostHog } from 'posthog-js/react'
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node
}

export default function AuthGuard({ children }) {
    const posthog = usePostHog()
    const { isAuthenticated, isInitialized, user } = useAuth()
    const isAdmin = user?.role === 'admin'
    const isUser = user?.role === 'user'
    const isChainer = user?.role === 'chainer'
    const { pathname } = useLocation()
    useEffect(() => {
        posthog.capture('$pageview', { pagename: pathname })
    }, [pathname])
    const [requestedLocation, setRequestedLocation] = useState(null)

    if (!isInitialized) {
        return <Spin />
    }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }
        return <Login />
    }
    const userPermittedRoutes = isAdmin
        ? [
              '/start-prompt-chain',
              '/ai-pages/',
              '/chatbots',
              '/chatflows',
              '/marketplaces',
              '/tools',
              '/credentials',
              '/apikey',
              '/usage',
              '/chatbot-edit',
              '/new-chatbot',
              '/',
              '/onboarding',
              '/bot-response',
              '/users',
              '/agents',
              '/runs',
              '/connections',
              '/redirect',
              '/user/account',
              '/apps',
              '/chat',
              'shared-workbook',
              '/blog',
              '/blogs',
              '/prompt-chains'
          ]
        : isUser
        ? [
              '/start-prompt-chain',
              '/templates',
              '/tools',
              '/playground',
              '/documents',
              '/workbook',
              '/connections',
              '/redirect',
              '/user/account',
              '/apps',
              'shared-workbook',
              '/dashboard',
              '/ai-pages'
          ]
        : isChainer
        ? [
              '/start-prompt-chain',
              '/playground',
              '/templates',
              '/documents',
              '/workbook',
              '/connections',
              '/redirect',
              '/user/account',
              '/apps',
              'shared-workbook',
              '/dashboard',
              '/prompt-chains',
              '/ai-pages'
          ]
        : []
    if (pathname !== '/' && user.role !== 'admin' && !userPermittedRoutes.find((p) => pathname.includes(p))) {
        return <Forbidden />
    }
    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <>{children}</>
}
