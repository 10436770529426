import client from './client'
const saveLocalConnection = (payload) => client.post(`/app-connections`, payload)
const getLocalConnections = (isSytem) => client.get(isSytem ? `/app-connections?system=true` : `/app-connections`)
export default {
    saveLocalConnection,
    getLocalConnections,
    deleteConnection: (id, isSystem) => client.delete(isSystem ? `/app-connections/${id}?system=true` : `/app-connections/${id}`),
    updateConnection: (id, payload, isSystem) =>
        client.put(isSystem ? `/app-connections/${id}?system=true` : `/app-connections/${id}`, payload),
    addConnection: (payload, isSystem) => client.post(isSystem ? `/app-connections?system=true` : `/app-connections`, payload)
}
