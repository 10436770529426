import ScrollToTop from './components/ScrollToTop'
import Routes from 'routes'
import ErrorBoundary from 'ErrorBoundary'
import NavigationScroll from 'layout/NavigationScroll'
import Toast from 'components/Toast'
import { useEffect, useState } from 'react'
import provider from 'api/provider'
import tools from 'api/tools'
import { dispatch } from 'store'
import agents from 'api/agents'
import Spin from 'tailwindui/pages/components/Spin'
import category from 'api/category'

const App = () => {
    const [loading, setLoading] = useState(false)
    const doSetup = async () => {
        setLoading(true)
        try {
            tools
                .getTools()
                .then((response) => {
                    if (response.data) {
                        dispatch({
                            type: 'SET_TOOLS',
                            payload: response.data
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            provider
                .getProviders()
                .then((response) => {
                    if (response.data) {
                        dispatch({
                            type: 'SET_PROVIDERS',
                            payload: response.data
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            category
                .getCategories()
                .then((response) => {
                    if (response.data) {
                        dispatch({
                            type: 'SET_CATEGORIES',
                            payload: response.data
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            agents
                .getLocalConnections()
                .then((response) => {
                    if (response.data) {
                        dispatch({
                            type: 'SET_CONNECTIONS',
                            payload: response.data
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        doSetup()
    }, [])
    if (loading) {
        return <Spin />
    }
    return (
        <ErrorBoundary>
            <NavigationScroll>
                <Toast />
                <ScrollToTop />
                <Routes />
            </NavigationScroll>
        </ErrorBoundary>
    )
}

export default App
