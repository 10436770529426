import client from './client'

const getProviders = () => client.get('/provider')

const getProvider = (id) => client.get(`/provider/${id}`)

const createNewProvider = (body) => client.post(`/provider`, body)

const updateProvider = (id, body) => client.put(`/provider/${id}`, body)

const deleteProvider = (id) => client.delete(`/provider/${id}`)

export default {
    getProvider,
    getProviders,
    createNewProvider,
    updateProvider,
    deleteProvider
}
