import { Suspense } from 'react'

import Spin from 'tailwindui/pages/components/Spin'

const Loadable = (Component) =>
    function WithLoader(props) {
        return (
            <Suspense fallback={<Spin />}>
                <Component {...props} />
            </Suspense>
        )
    }

export default Loadable
