// Definition: Reducer for socket actions
const initialState = {
    socketId: '',
    roomId: '',
    messages: [],
    lastMessage: null
}
const socketReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_SOCKET_ID':
            return {
                ...state,
                socketId: payload
            }
        case 'SET_ROOM_ID':
            return {
                ...state,
                roomId: payload
            }
        case 'SET_SOCKET_DATA':
            return {
                ...state,
                messages: [...state.messages, payload],
                lastMessage: payload
            }
        case 'CLEANUP_SOCKET':
            return {
                ...state,
                socketId: '',
                roomId: '',
                messages: []
            }
        default:
            return state
    }
}
export default socketReducer
